import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../../redux/customise/customiseActions";

import { Row, Col, Button, Tag } from "antd";
import { RiCloseLine, RiMoonClearLine, RiSunLine } from "react-icons/ri";

export default function HeaderTheme() {
  const [active, setActive] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();

  // Location
  const location = useHistory();

  // Theme Active
  let themeRef = useRef(null);
  themeRef = [];

  const [themeLocal, setThemeLocal] = useState();
  const [themeClickCheck, setThemeClickCheck] = useState(false);

  useEffect(() => {
    if (themeClickCheck == false) {
      if (location.location.search == "?theme=dark") {
        localStorage.setItem("theme", "dark");
        setThemeLocal("dark");
      } else if (location.location.search == "?theme=light") {
        localStorage.setItem("theme", "light");
        setThemeLocal("light");
      }
    }

    if (localStorage) {
      setThemeLocal(localStorage.getItem("theme"));
    }
  }, []);

  function themeClick(index) {
    setThemeClickCheck(true);

    if (index == 0) {
      document.querySelector("body").classList.replace("dark", "light");
      localStorage.setItem("theme", "light");
      setThemeLocal("light");

      dispatch(theme("light"));
    } else {
      document.querySelector("body").classList.replace("light", "dark");
      localStorage.setItem("theme", "dark");
      setThemeLocal("dark");

      dispatch(theme("dark"));
    }

    for (let i = 0; i < themeRef.length; i++) {
      if (index == i) {
        themeRef[i].classList.add("active");
      } else {
        themeRef[i].classList.remove("active");
      }
    }
  }

  return themeLocal === "dark" ? (
    <Button
      type="text"
      className="hp-mr-8"
      icon={<RiSunLine size={24} className="hp-text-color-black-60" />}
      onClick={() => themeClick(0)}
    />
  ) : (
    <Button
      type="text"
      className="hp-mr-8"
      icon={<RiMoonClearLine size={24} className="hp-text-color-black-60" />}
      onClick={() => themeClick(1)}
    />
  );
}
