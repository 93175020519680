import {
  Calendar,
  Category,
  Chart,
  CloseSquare,
  Graph,
  Paper,
  PaperPlus,
  People,
  Setting,
  Star,
  Document,
  Ticket,
  TwoUsers,
  User,
  Discovery,
} from "react-iconly";

const pages = [
  {
    header: "Utama",
    access: null,
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/dashboard",
    access: null,
  },
  {
    id: "patient",
    title: "Pasien",
    icon: <People set="curved" />,
    navLink: "/patient/data",
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "dokter-gigi",
      "dokter-umum",
      "admin-gigi",
      "admin-umum",
    ],
  },
  {
    id: "booking",
    title: "Booking",
    icon: <Calendar set="curved" className="remix-icon" />,
    navLink: "/booking/data",
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "dokter-gigi",
      "dokter-umum",
      "admin-gigi",
      "admin-umum",
    ],
  },
  {
    id: "cashier",
    title: "Kasir",
    icon: <Ticket set="curved" />,
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],
    children: [
      {
        id: "cashier-bill",
        title: "Daftar Tagihan",
        navLink: "/bill/data",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "cashier-payment",
        title: "Daftar Pembayaran",
        navLink: "/cashier/invoices",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
    ],
  },
  {
    id: "bill",
    title: "Tagihan Pasien",
    icon: <Document set="curved" className="remix-icon" />,
    navLink: "/bill/data",
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],
  },
  {
    header: "Ringkasan",
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],
  },
  {
    id: "statistic",
    title: "Statistik",
    icon: <Graph set="curved" className="remix-icon" />,
    navLink: "/statistic",
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],
  },
  {
    id: "spending",
    title: "Pengeluaran",
    icon: <Chart set="curved" className="remix-icon" />,
    navLink: "/spending/data",
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],
  },
  {
    id: "report",
    title: "Laporan",
    icon: <Graph set="curved" />,
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],
    children: [
      {
        id: "report-daily",
        title: "Laporan Harian",
        navLink: "/report/daily",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "report-price",
        title: "Biaya Klinik",
        navLink: "/report/clinic",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "report-credit",
        title: "Piutang",
        navLink: "/report/piutang",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "report-dinkes",
        title: "Laporan Dinkes",
        navLink: "/report/dinkes",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          // "admin-gigi",
          // "admin-umum",
        ],
      },
      {
        id: "report-tax",
        title: "Laporan Pajak",
        navLink: "/report/tax",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "medical-services",
        title: "Jasa Medis",
        navLink: "/report/medical-service",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "dokter-gigi",
          "dokter-umum",
        ],
      },
    ],
  },
  {
    header: "Lainnya",
    access: null,
  },
  {
    id: "package",
    title: "Pembelian Paket",
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/package/history",
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],
  },
  {
    id: "user-management",
    title: "Manajemen User",
    icon: <TwoUsers set="curved" />,
    access: ["klinik-gigi", "klinik-umum", "praktik-gigi", "praktik-umum"],

    children: [
      {
        id: "admin-clinic",
        title: "Admin Klinik",
        navLink: "/user/admin-clinic/data",
        access: ["klinik-gigi", "klinik-umum", "praktik-gigi", "praktik-umum"],
      },
      {
        id: "doctor",
        title: "Dokter",
        navLink: "/user/doctor/data",
        access: ["klinik-gigi", "klinik-umum", "praktik-gigi", "praktik-umum"],
      },
    ],
  },
  {
    id: "intergation",
    title: "Integrasi",
    icon: <Discovery className="remix-icon" />,
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],

    children: [
      {
        id: "satusehat",
        title: "Satusehat",
        navLink: "/integration/satusehat",
        access: ["klinik-gigi", "klinik-umum", "praktik-gigi", "praktik-umum"],
      },
      {
        id: "whatsapp-blast",
        title: "Whatsapp Blast",
        navLink: "/integration/whatsapp-blast",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
    ],
  },
  {
    id: "setting",
    title: "Pengaturan",
    icon: <Setting set="curved" />,
    access: [
      "klinik-gigi",
      "klinik-umum",
      "praktik-gigi",
      "praktik-umum",
      "admin-gigi",
      "admin-umum",
    ],

    children: [
      {
        id: "setting-clinic",
        title: "Pengaturan Klinik",
        navLink: "/setting/clinic",
        access: ["klinik-gigi", "klinik-umum", "praktik-gigi", "praktik-umum"],
      },
      {
        id: "setting-printer",
        title: "Pengaturan Printer",
        navLink: "/setting/printer",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "setting-operational-hours",
        title: "Jam Operasional",
        navLink: "/setting/operational-hours",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "setting-price",
        title: "Tarif Tindakan",
        navLink: "/setting/price/data",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "setting-payment-method",
        title: "Metode Pembayaran",
        navLink: "/setting/payment-method/data",
        access: ["klinik-gigi", "klinik-umum", "praktik-gigi", "praktik-umum"],
      },
      {
        id: "setting-stock-opname",
        title: "Stok Obat",
        navLink: "/setting/stock-opname/data",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "admin-gigi",
          "admin-umum",
        ],
      },
      {
        id: "setting-diagnose",
        title: "Diagnosis",
        navLink: "/setting/diagnose/data",
        access: [
          "klinik-gigi",
          "klinik-umum",
          "praktik-gigi",
          "praktik-umum",
          "dokter-gigi",
          "dokter-umum",
        ],
      },
    ],
  },
  {
    id: "profile",
    title: "Profil Saya",
    icon: <User set="curved" className="remix-icon" />,
    navLink: "/profile",
    access: null,
  },
];

export default pages;
