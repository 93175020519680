import { Col, Row } from "antd";
import bg from "../../../assets/images/banner/welcome-banner.webp";
import { useSelector } from "react-redux";
import LogoDark from "../../../assets/images/logo/logo-dark.webp";
import LogoSatuSehat from "../../../assets/images/logo/logo-satusehat.svg";

export default function WelcomeUser(props) {
  const theme = useSelector((state) => state.customise.theme);

  return (
    <>
      <div
        className="hp-w-100 hp-h-100 hp-p-8"
        style={{ background: `url('${bg}') top/cover` }}
      >
        <div className="hp-text-center">
          <img src={LogoDark} alt="Logo" width={"100px"} className="hp-mt-8" />
        </div>
        <div className="hp-d-flex hp-d-flex-justify-center hp-align-items-center hp-my-16 hp-w-100">
          <div
            style={{ width: "64px", height: "64px" }}
            className="hp-bg-color-black-0 hp-d-flex hp-d-flex-full-center hp-border-radius-full hp-mr-16"
          >
            <img
              src={props.logo}
              alt="Logo"
              width={"40px"}
              className="hp-mx-24 hp-bg-color-black-0"
            />
          </div>
          <div>
            <h5 className="hp-font-weight-600 hp-text-color-black-0 hp-mb-0">
              {props.name}
            </h5>

            <p className="hp-text-color-black-0 hp-mb-0">
              Selamat bergabung di <br />
              keluarga Kelola.net
            </p>
          </div>
        </div>

        <Row justify="center" className="hp-mt-16" style={{ opacity: "0.95" }}>
          <Col>
            <span
              className="hp-d-flex hp-bg-color-black-0 hp-px-16 hp-py-4 hp-border-radius-full"
              style={{ width: "auto" }}
            >
              <img
                src={LogoSatuSehat}
                alt="Satu Sehat"
                width={"20px"}
                className="hp-mr-8"
              />

              <small
                className="hp-mb-0 hp-text-color-black-100 hp-text-left"
                style={{ fontSize: "10px" }}
              >
                Kelola.net telah terintegrasi <br />
                dengan Satu Sehat
              </small>
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
}
