import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ConfigProvider } from "antd";

import Router from "./router/Router";
import Maintenance from "./view/pages/error/maintenance";
import locale from "antd/es/locale/id_ID";

export default function App() {
  // Redux
  const customise = useSelector((state) => state.customise);

  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return <Maintenance />;
  }

  useEffect(() => {}, []);

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", customise.language);
  }, [customise]);

  return (
    <ConfigProvider direction={customise.direction} locale={locale}>
      <Router />
    </ConfigProvider>
  );
}
