import React, { useEffect, useState } from "react";
import { RiCloseLine, RiMore2Fill } from "react-icons/ri";
import { IoShareOutline } from "react-icons/io5";
import { Alert, Button, Col, Image, Row } from "antd";
import AppIcon from "../../../assets/images/logo/logo-app.png";

export default function AppBanner(props) {
  const [deviceType, setDeviceType] = useState(null);

  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setDeviceType("windows-phone");
    }

    if (/android/i.test(userAgent)) {
      setDeviceType("android");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType("ios");
    }
  }

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("PWA installed");
        } else {
          console.log("User declined PWA installation");
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    getMobileOperatingSystem();
  }, []);

  return (
    <>
      {(deviceType === "android" || deviceType === "windows-phone") &&
      isChrome ? (
        <Alert
          onClose={props.onClose}
          message={
            <div className="hp-d-flex hp-align-items-center ">
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={AppIcon}
                className="hp-border-radius hp-p-4"
                width={"48px"}
                alt={"App Icon"}
              />
              <div className="hp-ml-8 hp-text-color-primary-1">
                <span>Akses lebih cepat melalui Homescreen</span>
              </div>
              <div className="hp-ml-8">
                <Button
                  type="primary"
                  size="small"
                  onClick={handleInstallClick}
                >
                  Install
                </Button>
              </div>
            </div>
          }
          closable
          className="hp-w-100 hp-app-banner hp-bg-color-primary-4 hp-text-color-primary-1 hp-text-color-dark-0"
          closeIcon={
            <RiCloseLine size={24} className="hp-text-color-primary-1" />
          }
        />
      ) : (deviceType === "android" || deviceType === "windows-phone") &&
        !isChrome ? (
        <Alert
          onClose={props.onClose}
          message={
            <div className="hp-d-flex hp-align-items-center ">
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={AppIcon}
                className="hp-border-radius hp-p-4"
                width={"48px"}
                alt={"App Icon"}
              />
              <div className="hp-ml-8 hp-text-color-primary-1">
                <span>Install Aplikasi Kelola.net dengan tekan </span>
                <span>
                  <IoShareOutline /> pada browser kamu, lalu{" "}
                  <b>Tambah ke Home Screen</b>
                </span>
              </div>
            </div>
          }
          closable
          className="hp-w-100 hp-app-banner hp-bg-color-primary-4 hp-text-color-primary-1 hp-text-color-dark-0"
          closeIcon={
            <RiCloseLine size={24} className="hp-text-color-primary-1" />
          }
        />
      ) : (
        <Alert
          onClose={props.onClose}
          message={
            <div className="hp-d-flex hp-align-items-center">
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={AppIcon}
                className="hp-border-radius hp-p-4"
                width={"64px"}
                alt={"App Icon"}
              />
              <div className="hp-ml-8 hp-text-color-primary-1">
                <span>Install Aplikasi Kelola.net dengan tekan </span>
                <span>
                  <RiMore2Fill /> pada browser kamu, lalu{" "}
                  <b>Install Aplikasi</b>
                </span>
              </div>
            </div>
          }
          closable
          className="hp-w-100 hp-app-banner hp-bg-color-primary-4 hp-bg-color-dark-primary-4 hp-text-color-primary-1 hp-text-color-dark-0"
          closeIcon={
            <RiCloseLine size={24} className="hp-text-color-primary-1" />
          }
        />
      )}
    </>
  );
}
