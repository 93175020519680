import React from "react";

import { Card, Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import "moment/locale/id";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Paragraph } = Typography;

export default function MenuList(props) {
  const history = useHistory();
  const { url = "", icon, title } = props;

  return (
    <>
      <Card
        className="hp-cursor-pointer hp-mb-16"
        bodyStyle={{ padding: "8px" }}
        onClick={() => history.push(url)}
      >
        <Row gutter={[16, 16]} align={"middle"}>
          <Col>{icon}</Col>
          <Col flex={"auto"}>
            <h6 className="hp-mb-0">{title}</h6>
          </Col>

          <Col>
            <RiArrowRightSLine
              size={24}
              className="remix-icon hp-text-color-black-40"
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
