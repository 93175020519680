import { Button, Col, Row } from "antd";
import bg from "../../../assets/images/banner/satusehat-banner.webp";
import { useSelector } from "react-redux";
import Logo from "../../../assets/images/logo/logo.webp";
import LogoSatuSehat from "../../../assets/images/logo/logo-satusehat.svg";

export default function SatuSehat(props) {
  const theme = useSelector((state) => state.customise.theme);

  return (
    <>
      <div
        className="hp-w-100 hp-h-100 hp-p-8"
        style={{ background: `url('${bg}') center bottom/cover` }}
      >
        <div className="hp-text-center">
          <img src={Logo} alt="Logo" width={"100px"} className="hp-mt-8" />
        </div>

        <div className="hp-d-flex hp-d-flex-justify-center hp-align-items-center hp-mt-16 hp-mb-8 hp-w-100">
          <div
            style={{ width: "80px", height: "80px" }}
            className="hp-d-flex hp-d-flex-full-center hp-border-radius-full"
          >
            <img src={LogoSatuSehat} alt="Logo" width={"64px"} />
          </div>
          <div>
            <h5 className="hp-font-weight-600 hp-text-color-black-100">
              Kelola.net telah terintegrasi <br />
              dengan Platform Satu Sehat
            </h5>
          </div>
        </div>

        <div className="hp-text-center">
          <a
            href="https://satusehat.kemkes.go.id/platform/system-rme-list"
            target="_blank"
          >
            <Button
              type="primary"
              className="hp-border-radius-full hp-bg-color-black-100 hp-border-none"
            >
              Cek Sekarang
            </Button>
          </a>
        </div>
      </div>
    </>
  );
}
