import React, { useState } from "react";
// App Icon
import MenuIconBooking from "../../../assets/images/icon/icon-booking.webp";
import MenuIconPatient from "../../../assets/images/icon/icon-patient.webp";

import MenuIconCashier from "../../../assets/images/icon/icon-cashier.webp";
import MenuIconReceipt from "../../../assets/images/icon/icon-receipt.webp";
import MenuIconInvoice from "../../../assets/images/icon/icon-invoice.webp";

import MenuIconStats from "../../../assets/images/icon/icon-statistic.webp";
import MenuIconSpending from "../../../assets/images/icon/icon-spending.webp";

import MenuIconReport from "../../../assets/images/icon/icon-report.webp";
import MenuIconDaily from "../../../assets/images/icon/icon-daily.webp";
import MenuIconClinicCash from "../../../assets/images/icon/icon-clinic-cash.webp";
import MenuIconReceivable from "../../../assets/images/icon/icon-receivable.webp";
import MenuIconDinkes from "../../../assets/images/icon/icon-dinkes-report.webp";
import MenuIconTax from "../../../assets/images/icon/icon-tax-report.webp";
import MenuIconMedicFee from "../../../assets/images/icon/icon-medic-fee.webp";

import MenuIconCart from "../../../assets/images/icon/icon-cart.webp";

import MenuIconUser from "../../../assets/images/icon/icon-user.webp";
import MenuIconDoctor from "../../../assets/images/icon/icon-doctor.webp";
import MenuIconAdmin from "../../../assets/images/icon/icon-admin.webp";

import MenuIconIntegration from "../../../assets/images/icon/icon-integration.webp";
import MenuIconWhatsapp from "../../../assets/images/icon/icon-whatsapp.webp";
import MenuIconSatusehat from "../../../assets/images/icon/icon-satusehat.webp";

import MenuIconSetting from "../../../assets/images/icon/icon-setting.webp";
import MenuIconClinic from "../../../assets/images/icon/icon-clinic.webp";
import MenuIconPrinter from "../../../assets/images/icon/icon-printer.webp";
import MenuIconClock from "../../../assets/images/icon/icon-operational-hours.webp";
import MenuIconPrice from "../../../assets/images/icon/icon-price.webp";
import MenuIconPaymentMethod from "../../../assets/images/icon/icon-payment-method.webp";
import MenuIconMedicine from "../../../assets/images/icon/icon-medicine.webp";
import MenuIconDiagnose from "../../../assets/images/icon/icon-diagnose.webp";
import { Col, Image, Row } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ModalDrawer from "../modal-drawer";
import MenuList from "../card-list/_newmenu";

export default function HomeMenu(props) {
  const [cashierVisible, setCashierVisible] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [userVisible, setUserVisible] = useState(false);
  const [integrateVisible, setIntegrateVisible] = useState(false);
  const [settingVisible, setSettingVisible] = useState(false);

  return (
    <>
      <div className="hp-show-mobile hp-home-icon">
        <Row gutter={[24, 24]} className="hp-mb-24">
          <Col span={6} md={4} className="hp-text-center">
            <Link to="/booking/data">
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconBooking}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Booking
              </div>
            </Link>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <Link to="/patient/data">
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconPatient}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Pasien
              </div>
            </Link>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <div
              onClick={() => setCashierVisible(true)}
              className="hp-cursor-pointer"
            >
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconCashier}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Kasir
              </div>
            </div>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <Link to="/bill/data">
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconInvoice}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Tagihan Pasien
              </div>
            </Link>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <Link to="/statistic">
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconStats}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Statistik
              </div>
            </Link>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <Link to="/spending/data">
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconSpending}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Pengeluaran
              </div>
            </Link>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <div
              onClick={() => setReportVisible(true)}
              className="hp-cursor-pointer"
            >
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconReport}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Laporan
              </div>
            </div>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <Link to="/package/history">
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconCart}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Pembelian Paket
              </div>
            </Link>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <div
              onClick={() => setUserVisible(true)}
              className="hp-cursor-pointer"
            >
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconUser}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Manajemen User
              </div>
            </div>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <div
              onClick={() => setIntegrateVisible(true)}
              className="hp-cursor-pointer"
            >
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconIntegration}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Integrasi
              </div>
            </div>
          </Col>
          <Col span={6} md={4} className="hp-text-center">
            <div
              onClick={() => setSettingVisible(true)}
              className="hp-cursor-pointer"
            >
              <Image
                style={{ aspectRatio: " 1 / 1", maxWidth: "64px" }}
                preview={false}
                src={MenuIconSetting}
                className="hp-mb-4"
                width={"100%"}
                alt={"App Icon"}
              />

              <div className="hp-text-color-black-100 hp-text-color-dark-0">
                Pengaturan
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ModalDrawer
        title="Kasir"
        width={500}
        visible={cashierVisible}
        onCancel={() => setCashierVisible(false)}
        onClose={() => setCashierVisible(false)}
        closable={true}
        bodyStyle={{ padding: "24px" }}
        footer={null}
      >
        <MenuList
          title="Daftar Tagihan"
          url="/bill/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconInvoice}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Daftar Pembayaran"
          url="/cashier/invoices"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconReceipt}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
      </ModalDrawer>
      <ModalDrawer
        title="Laporan"
        width={500}
        visible={reportVisible}
        onCancel={() => setReportVisible(false)}
        onClose={() => setReportVisible(false)}
        closable={true}
        bodyStyle={{ padding: "24px" }}
        footer={null}
      >
        <MenuList
          title="Laporan Harian"
          url="/report/daily"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconDaily}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Laporan Klinik"
          url="/report/clinic"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconClinicCash}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Piutang"
          url="/report/piutang"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconReceivable}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Laporan Dinkes"
          url="/report/dinkes"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconDinkes}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Laporan Pajak"
          url="/report/tax"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconTax}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Jasa Medis"
          url="/report/medical-service"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconMedicFee}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
      </ModalDrawer>
      <ModalDrawer
        title="Manajemen User"
        width={500}
        visible={userVisible}
        onCancel={() => setUserVisible(false)}
        onClose={() => setUserVisible(false)}
        closable={true}
        bodyStyle={{ padding: "24px" }}
        footer={null}
      >
        <MenuList
          title="Admin Klinik"
          url="/user/admin-clinic/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconAdmin}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Dokter"
          url="/user/doctor/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconDoctor}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
      </ModalDrawer>
      <ModalDrawer
        title="Integrasi"
        width={500}
        visible={integrateVisible}
        onCancel={() => setIntegrateVisible(false)}
        onClose={() => setIntegrateVisible(false)}
        closable={true}
        bodyStyle={{ padding: "24px" }}
        footer={null}
      >
        <MenuList
          title="Satusehat"
          url="/integration/satusehat"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconSatusehat}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Whatsapp Blast"
          url="/integration/whatsapp"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconWhatsapp}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
      </ModalDrawer>
      <ModalDrawer
        title="Pengaturan"
        width={500}
        visible={settingVisible}
        onCancel={() => setSettingVisible(false)}
        onClose={() => setSettingVisible(false)}
        closable={true}
        bodyStyle={{ padding: "24px" }}
        footer={null}
      >
        <MenuList
          title="Pengaturan Klinik"
          url="/setting/clinic"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconClinic}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Pengaturan Printer"
          url="/setting/printer"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconPrinter}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Jam Operasional"
          url="/setting/operational-hours"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconClock}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Tarif Tindakan"
          url="/setting/price/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconClinic}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Tarif Tindakan"
          url="/setting/price/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconPrice}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Metode Pembayaran"
          url="/setting/payment-method/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconPaymentMethod}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Stok Obat"
          url="/setting/stock-opname/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconMedicine}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
        <MenuList
          title="Diagnosis"
          url="/setting/diagnose/data"
          icon={
            <Image
              style={{ aspectRatio: " 1 / 1", maxWidth: "48px" }}
              preview={false}
              src={MenuIconDiagnose}
              className="hp-mb-4"
              width={"100%"}
              alt={"App Icon"}
            />
          }
        />
      </ModalDrawer>
    </>
  );
}
