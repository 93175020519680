const dataList = JSON.parse(localStorage.getItem("data-list"));
const dataListConfig = {
  patient: dataList?.patient ?? "list",
  booking: dataList?.booking ?? "calendar",
  adminClinic: dataList?.adminClinic ?? "list",
  doctor: dataList?.doctor ?? "list",
  price: dataList?.price ?? "list",
  paymentMethod: dataList?.paymentMethod ?? "list",
  stockOpname: dataList?.stockOpname ?? "list",
  diagnose: dataList?.diagnose ?? "list",
};

export default dataListConfig;
