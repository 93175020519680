import React, { useEffect, useState } from "react";

import { Col, Drawer, Modal, Row } from "antd";
import { RiCloseLine } from "react-icons/ri";

export default function ModalDrawer(props) {
  // Device Size Handling
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return dimensions.width < 1199 ? (
    <Drawer
      className="hp-bottom-sheet"
      title={props.title}
      headerStyle={{ border: 0 }}
      bodyStyle={{ borderRadius: "24px 24px 0px 0px", padding: "16px" }}
      open={props.visible}
      onClose={props.onClose}
      placement="bottom"
      height={"90vh"}
      closable={props.closable}
      closeIcon={
        <RiCloseLine size={24} className="remix-icon hp-text-color-dark-0" />
      }
      footer={props.footer}
    >
      {props.children}
    </Drawer>
  ) : (
    <Modal
      title={null}
      headerStyle={{ border: 0 }}
      open={props.visible}
      bodyStyle={props.bodyStyle}
      onCancel={props.onClose}
      footer={props.footer}
      width={props.width}
      closable={props.closable}
      closeIcon={
        <RiCloseLine size={24} className=" remix-icon hp-text-color-dark-0" />
      }
    >
      <div className="hp-mb-24">{props.title}</div>
      {props.children}
    </Modal>
  );
}
