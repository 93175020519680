import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import CustomiseTheme from "./components/customise";
import ScrollTop from "./components/scroll-to-top";
import AppBanner from "./components/app-banner";

const { Content } = Layout;

export default function VerticalLayout(props) {
  const { children } = props;

  const [visible, setVisible] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  const [appBannerShow, setAppBannerShow] = useState(true);
  const [userHasPWA, setUserHasPWA] = useState(false);

  function isPWA() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) =>
        window.matchMedia("(display-mode: " + displayMode + ")").matches
    );
  }
  useEffect(() => {
    if (isPWA()) {
      setUserHasPWA(true);
      setAppBannerShow(false);
    } else {
      setUserHasPWA(false);
      setAppBannerShow(true);
    }
  }, []);

  return (
    <Layout className="hp-app-layout">
      <Sidebar visible={visible} setVisible={setVisible} />

      <Layout className="hp-bg-color-dark-90">
        <MenuHeader setVisible={setVisible} />

        <Content
          className={
            appBannerShow === true &&
            userHasPWA === false &&
            "hp-content-show-banner"
          }
        >
          {userHasPWA === false && (
            <AppBanner onClose={() => setAppBannerShow(false)} />
          )}
          {children}
        </Content>

        {/* <MenuFooter /> */}
      </Layout>

      {/* <CustomiseTheme /> */}

      {/* <ScrollTop /> */}
    </Layout>
  );
}
