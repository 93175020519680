import dataListConfig from "../../configs/dataListConfig";
import * as actionTypes from "./dataListTypes";

const INITIAL_STATE = {
  patient: dataListConfig.patient,
  booking: dataListConfig.booking,
  adminClinic: dataListConfig.adminClinic,
  doctor: dataListConfig.doctor,
  price: dataListConfig.price,
  paymentMethod: dataListConfig.paymentMethod,
  stockOpname: dataListConfig.stockOpname,
  diagnose: dataListConfig.diagnose,
};

const dataListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DATA_LIST_TYPE:
      return {
        ...state,
        [action.payload.dataKey]: action.payload.value,
      };

    default:
      return state;
  }
};

export default dataListReducer;
